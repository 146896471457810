import React from "react"
import styled from "styled-components"

const Button = styled.button`
  padding: 10px 40px 10px 40px;
  border: 0px;
  font-size: 0.9em;
  font-family: roboto;
  position: relative;
  border-radius: 100px;
  transition: all 0.2s;
  background: transparent;
  z-index: 200;
  cursor: pointer;
  margin: 2px;
  &:before {
    content: "";
    width: 15px;
    height: 2px;
    background: #555;
    position: absolute;
    border-radius: 10px;
    top: 50%;
    left: 10%;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 72px;
    background-color: rgb(252, 242, 43);
    border-radius: 40px;
    transition: all 0.2s ease-in 0s;
  }
  &:hover {
    &:after {
      width: 100%;
    }
    &:before {
      animation: button 500ms ease-in;
    }
  }
  @keyframes button {
    0% {
      left: 10%;
    }
    50% {
      width: 50%;
      left: 50%;
    }
    60% {
      opacity: 0;
    }
  }
`

const IButton = styled.button`
  border: 0px;
  padding: 10px;
  font-size: 15px;
  position: relative;
  transition: all 0.2s linear;
  cursor: pointer;
  width: 100px;
  text-align: left;
  outline: 0px;
  background: transparent;
  &:after {
    content: "";
    position: absolute;
    background: #333;
    top: 48%;
    right: 0px;
    width: 25%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 2px;
    transition: all 0.2s linear;
  }
  &:hover {
    &:after {
      width: 78%;
    }
    padding-right: 50px;
    border-radius: 50px;
    background: #eee;
  }
  &:active {
    background: #dedede;
    animation: icon-button-animate 2s;
  }
`

export const IconButton = props => (
  <IButton {...props}>{props.children}</IButton>
)
export default props => <Button {...props}>{props.children}</Button>
