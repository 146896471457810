import React from "react"
import { Link } from "gatsby"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/Company"

const KasthamandalPage = () => (
  <Layout showOnlyHome={true}>
    <SEO title="Kasthamandal Wood And Seasoning Industries Pvt. Ltd." />
    <Company
      title="Kasthamandal Wood And Seasoning Industries Pvt. Ltd."
      phone="+977- 9851105748, 9801105748"
      email="kumar_rokka@hotmail.com / deepak.rc27@gmail.com"
    >
      <div className="images">
        <Zoom>
          <img src="/company/rosin.jpg" />
        </Zoom>
        <Zoom>
          <img src="/company/rosin1.jpg" />
        </Zoom>
      </div>
      <div className="content">
        <h5>Company Detail</h5>
        <p>
          All kinds of wooden business wood treatment, wood seasoning and
          manufacturing of furniture, ply & international class of parquet
          Import & Export.
        </p>
        <h5>Objective</h5>
        <ul>
          <li>
            Purchase of wood from the district forest office, community forest
            office, personal forest and import raw wooden logs, raw woods and
            size lumber from different countries, like Vietnam, Indonesia,
            Malaysia, Myanmar, Laos, Cambodia, South Africa, Canada And many
            more.
          </li>
          <li>
            Plantation of trees, herbs and other plants let them to grow to use
            like raw material cutting them and processing for products.
          </li>
          <li>
            Marketing of wood &amp;wooden products in Nepal as well as third
            countries.
          </li>
        </ul>
        <address>
          OCR Registration No.: 46743/063/064
          <br />
          PAN/VAT No.: 302631256
          <br />
          Year of Establishment: 2007
          <br />
          Corporate Address: Madhabaliya ward no.- 9, Rupandehi,
          <br />
          Bhairahawa, Nepal
        </address>
        <h5> Key Persons: Mr. Basanta Rokka Chhetri </h5>
        <ul>
          <li>Mr. Kumar Rokka Chhetri (Chairman)</li>
          <li>Mr. Basanta Rokka Chhetri (Director)</li>
          <li>Mr. Bharat Bdr Rokka Chhetri (Director)</li>
          <li>Mr. Ishowar Bdr Rokka Chhetri (Director)</li>
          Mr. Deepak Rokka (Director)
        </ul>
      </div>
      <div className="map">
        <iframe
          id="map-iframe"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3536.815707537663!2d83.48781281505904!3d27.56822948284983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39969b46b9c2e04f%3A0xf311c0748a566267!2sMount%20Rosin%20%26%20Turpentine%20Industries%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1585976562930!5m2!1sen!2snp"
          frameborder="0"
          allowfullscreen=""
        ></iframe>
      </div>
    </Company>
  </Layout>
)

export default KasthamandalPage
