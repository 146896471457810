import React, { useState } from "react"
import styled from "styled-components"
import Icon from "react-icons-kit"

import { phone } from "react-icons-kit/feather/phone"

import Button from "./Button"

const Div = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 20px;
  .first-col {
    width: 60%;
    min-width: 320px;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    p.block {
      width: 100%;
      padding: 10px;
      h1 {
        margin: 10px 0px 20px;
        font-size: 3em;
        font-family: Oswald;
      }
      span {
        display: block;
      }
    }
    .images {
      width: 30%;
      min-width: 250px;
      padding: 10px;
      img {
        border-radius: 5px;
      }
    }
    .content {
      min-width: 320px;
      width: 70%;
      padding: 10px;
    }
    .map {
      width: 100%;
      display: block;
      iframe {
        border: 0px;
        width: 100%;
        height: 400px;
      }
    }
  }
  .pdf-col {
    min-width: 340px;
    width: 40%;
    embed {
      width: 100%;
      height: 100%;
      min-height: 500px;
    }
  }
`

const Company = props => {
  const [currentTab, setCurrentTab] = useState(0)
  const { title, website, phone, email, pdf } = props
  return (
    <Div>
      <div className="first-col">
        <p className="block">
          <h1>{title}</h1>
          <a href={website}>
            <Button>Website</Button>
          </a>
          <br />
          <br />
          <span>Phone: {phone}</span>
          <span>Email: {email}</span>
        </p>
        {props.children}
      </div>
      {pdf && (
        <div className="pdf-col">
          {pdf.length > 1 &&
            pdf.map((item, i) => (
              <Button onClick={() => setCurrentTab(i)}>{item.title}</Button>
            ))}
          <embed src={pdf[currentTab].src || ""} />
        </div>
      )}
    </Div>
  )
}

export default Company
